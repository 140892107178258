import './styles.scss';
import React from 'react';

import classNames from 'classnames';
import { string, bool } from 'prop-types';
import prefixFactory from '../../helpers';

import {ReactComponent as IconTrash} from '../../assets/icons/icon-trash.svg';
import {ReactComponent as IconPlus} from '../../assets/icons/icon-plus.svg';

const prefix = prefixFactory('button--');

const types = prefix([
  'primary',
  'secondary',
  'tertiary',
]);

const sizes = prefix([
  'md',
  'lg',
  'xlg',
]);

const Button = ({
  type,
  size,
  value,
  submit,
  iconTrash,
  iconPlus,
  ...rest
}) => {
  const classes = classNames(
    'button',
    types[type],
    sizes[size],
  );

  return (
      <button
        {...rest}
        type={submit ? 'submit' : 'button'}
        className={classes}
      >
        {iconTrash && (
          <IconTrash />
        )}
        {iconPlus && (
          <IconPlus />
        )}
        {value}
    </button>
  );
};

Button.propTypes = {
  value: string.isRequired,
  type: string,
  size: string,
  submit: bool,
  iconTrash: bool,
  iconPlus: bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'lg',
  submit: false,
  iconTrash: false,
  iconPlus: false,
};

export default Button;
