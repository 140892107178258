import './styles.scss';

import React, { useState, useRef, useEffect } from 'react';

import { Form } from '@unform/web';
import classNames from 'classnames';
import * as Yup from 'yup';

import IconCheck from '../../assets/icons/icon-check.svg';
import { ReactComponent as IconChevron } from '../../assets/icons/icon-chevron.svg';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import Modal from '../../components/Modal';
import Radio from '../../components/Radio';
import Title from '../../components/Title';
import api from '../../services/Auth/api';

const radioArray = [
  {
    label: 'Administrador',
    name: 'administrador',
  },
  {
    label: 'Visualizador',
    name: 'visualizador',
  },
];

const titles = [
  {
    title: 'Nome do usuario',
  },
  {
    title: 'E-mail',
  },
];

const Users = () => {
  const [modal, setModal] = useState(false);
  const [typeUser, setTypeUser] = useState('');
  const [send, setSend] = useState(false);
  const [sendUser, setSendUser] = useState(false);
  const [checkedUser, setCheckedUser] = useState(false);
  const [activeUser, setActiveUser] = useState(-1);
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [activeDelete, setActiveDelete] = useState('');

  const formRef = useRef(null);
  const formRefUser = useRef(null);

  async function handleDelete (id) {
    await api.delete(`/user/${id}`);
  }

  async function handleSubmit (data, { reset }) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Digite o nome.'),
        password: Yup.string().required('Digite a senha.'),
        password_confirm: Yup.string().required('Confirme a senha.'),
        email: Yup.string().required('Digite o email.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const admin = typeUser === 'Administrador'
        ? 1
        : 0;

      const password = formRef.current.getFieldValue('password');
      const passwordConfirm = formRef.current.getFieldValue('password_confirm');
      if (password === passwordConfirm) {
        const updateData = {
          ...data,
          admin,
        };

        await api.post('/user', updateData);
        setSend(true);
      } else {
        formRef.current.setFieldError('password', ' ');
        formRef.current.setFieldError('password_confirm', 'As senhas são diferentes');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }

    reset();
  }

  async function handleSubmitUser (data) {
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required('Digite a senha.'),
        password_confirm: Yup.string().required('Confirme a senha.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const password = formRefUser.current.getFieldValue('password');
      const passwordConfirm = formRefUser.current.getFieldValue('password_confirm');

      if (password === passwordConfirm) {
        await api.put(`/user/${data.id}/update-password`, data);
        setSendUser(true);
      } else {
        formRefUser.current.setFieldError('password', ' ');
        formRefUser.current.setFieldError('password_confirm', 'As senhas estão diferentes');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRefUser.current.setErrors(errorMessages);
      }
    }
  }

  useEffect(() => {
    async function getUsers () {
      const response = await api.get('/user');
      setUsers(response.data.success);
    }

    getUsers();
  }, []);

  return (
    <section className="users">
      <div className="container">
        <div className="users__header">
          <Title
            isTitle
            size="xlg"
            color="blue"
            weight="semi-bold"
            value="Lista de Usuários"
          />
          <Button
            type="primary"
            size="md"
            value="Novo Usuário"
            iconPlus
            onClick={() => setModal(! modal)}
          />
        </div>
        <div className="users__card">
          <div className="users__card-head">
            {titles.map((item) => (
              <Title
                size="xxs"
                color="blue-dark"
                weight="semi-bold"
                value={item.title}
              />
            ))}
          </div>
          {users.map((item, index) => {
            const styled = classNames(
              'users__card-accordion',
              activeUser === index && checkedUser && 'users__card-accordion--checked',
            );

            return (
              <>
                <div className={styled}>
                  <div
                    className="users__card-accordion-head"
                    onClick={() => {
                      setCheckedUser(! checkedUser);
                      setActiveUser(index);
                    }}
                    role="presentation"
                  >
                    <p className="users__card-accordion-head-item">{item.name}</p>
                    <p className="users__card-accordion-head-item">{item.email}</p>
                    <IconChevron />
                  </div>
                  <div className="users__card-accordion-body">
                    <Form
                      className="users__card-accordion-body-change"
                      onSubmit={handleSubmitUser}
                      ref={formRefUser}
                    >
                      <Input
                        input
                        hasLabel
                        label="Alterar senha:"
                        appearence="secondary"
                        type="text"
                        size="sm"
                        placeholder="Insira a nova senha"
                        name="password"
                      />

                      <Input
                        input
                        hasLabel
                        label="Confirmar senha:"
                        appearence="secondary"
                        type="text"
                        size="sm"
                        placeholder="Confirmar senha"
                        name="password_confirm"
                      />

                      <Input
                        input
                        hidden
                        value={item.id}
                        name="id"
                      />
                      <Button
                        value="Confirmar Alteração"
                        type="secondary"
                        size="md"
                        onClick={() => setEditUser(! editUser)}
                        submit
                      />
                    </Form>
                    <div className="users__card-accordion-body-delete">
                      <Button
                        value="Excluir Usuário"
                        type="tertiary"
                        size="md"
                        iconTrash
                        onClick={() => {
                          setConfirmDeleteUser(! confirmDeleteUser);
                          setActiveDelete(index);
                        }}
                        submit
                      />
                    </div>
                  </div>
                </div>
                {sendUser && (
                  <Modal
                    checked={editUser}
                    onChecked={setEditUser}
                  >
                    <div className="modal__wrapper-card-success">
                      <img
                        src={IconCheck}
                        alt=""
                      />
                      <h1 className="modal__wrapper-card-success-title">Senha alterada com sucesso</h1>
                    </div>
                  </Modal>
                )}
              </>
            );
          })}
        </div>
        <Modal
          checked={modal}
          onChecked={setModal}
          onSend={setSend}
        >
          {! send ? (
            <div>
              <Title
                isTitle
                size="xlg"
                color="blue"
                weight="medium"
                value="Novo Usuário"
              />
              <Form
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <Input
                  input
                  hasLabel
                  label="Nome"
                  appearence="secondary"
                  type="text"
                  size="md"
                  placeholder="Insira o seu nome"
                  name="name"
                />
                <Input
                  input
                  hasLabel
                  label="E-mail"
                  appearence="secondary"
                  type="text"
                  size="md"
                  placeholder="Insira o e-mail"
                  name="email"
                />
                <div className="row">
                  <Input
                    input
                    hasLabel
                    label="Senha"
                    appearence="secondary"
                    type="password"
                    size="md"
                    placeholder="Insira a senha"
                    name="password"
                  />
                  <Input
                    input
                    hasLabel
                    label="Confirmar senha"
                    appearence="secondary"
                    type="password"
                    size="md"
                    placeholder="Confirmar senha"
                    name="password_confirm"
                  />
                </div>
                <Title
                  size="md"
                  color="blue-dark"
                  weight="medium"
                  value="Tipo de Usuário:"
                />
                <div className="radio__multiple">
                  {radioArray.map((item) => (
                    <Radio
                      key={item.name}
                      id={item.name}
                      label={item.label}
                      isChecked={typeUser === item.label}
                      onCheck={(e) => setTypeUser(e.target.value)}
                    />
                  ))}
                </div>
                <Button
                  value="Criar usuário"
                  submit
                />
              </Form>
            </div>
          ) : (
            <>
              <div className="modal__wrapper-card-success">
                <img
                  src={IconCheck}
                  alt=""
                />
                <h1 className="modal__wrapper-card-success-title">Usuário Cadastrado</h1>
              </div>
            </>
          )}
        </Modal>

        {users.map((item, index) => (
          <>
            {activeDelete === index && (
              <>
                <Modal
                  checked={confirmDeleteUser}
                  onChecked={setConfirmDeleteUser}
                >
                  <div className="modal__wrapper-card-delete">
                    <Title
                      isTitle
                      size="xlg"
                      color="blue-dark"
                      weight="medium"
                      value="Tem certeza que deseja excluir esse usuário?"
                    />
                    <div className="modal__wrapper-card-delete-buttons">
                      <Button
                        value="NÃO"
                        type="secondary"
                        size="xlg"
                        onClick={() => setConfirmDeleteUser(! confirmDeleteUser)}
                      />
                      <Button
                        value="SIM"
                        type="tertiary"
                        size="xlg"
                        onClick={() => {
                          setDeleteUser(! deleteUser);
                          setConfirmDeleteUser(! confirmDeleteUser);
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  </div>
                </Modal>
                <Modal
                  checked={deleteUser}
                  onChecked={setDeleteUser}
                >
                  <div className="modal__wrapper-card-success">
                    <img
                      src={IconCheck}
                      alt=""
                    />
                    <h1 className="modal__wrapper-card-success-title">Usuário excluído com sucesso</h1>
                  </div>
                </Modal>
              </>
            )}
          </>
        ))}
      </div>
    </section>
  );
};

export default Users;
