import React from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import Head from './layout/Head';
import { isAuthenticated } from './services/Auth/auth';
import Auth from './views/Auth';
import ShowSubmission from './views/Submissions/Show';
import Laws from './views/Laws';
import Submissions from './views/Submissions';
import Users from './views/Users';

/**
 * Create Private or Guest routes.
 */
const AuthenticatedRoute = (header = true, guest = false) => {
  const pathname = guest
    ? '/submissoes'
    : '/';

  return ({ component: View, ...rest }) => {
    const authenticated = isAuthenticated();

    /**
     * Return the correct component.
     */
    const routeRenderer = (props) => {

      /**
       * If user is authenticated
       * and route is guest only
       * we redirect.
       * or
       * If this is a private route
       * and user is not authenticated
       * we redirect.
       */
      if (
        guest && authenticated
        || ! guest && ! authenticated
      ) {
        return <Redirect
          to={{
            pathname,
            from: props.location,
          }}
        />
      }

      /**
       * Now we can just return the view.
       */
      return <View
        {...props}
      />
    };

    return (
      <>
        {header && <Head />}
        <Route
          {...rest}
          render={routeRenderer}
        />
      </>
    );
  };
};

/**
 * PrivateRoute wrapper.
 */
const PrivateRoute = AuthenticatedRoute();

/**
 * GuestRoute wrapper.
 */
const GuestRoute = AuthenticatedRoute(false, true);

const Routes = () => (
  <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
    <Switch>
      <GuestRoute exact path="/" component={Auth} />
      <PrivateRoute exact path="/submissoes" component={Submissions} />
      <PrivateRoute path="/submissoes/:id" component={ShowSubmission} />
      <PrivateRoute path="/usuarios" component={Users} />
      <PrivateRoute path="/leis" component={Laws} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
