import React from 'react';

import { bool, string } from 'prop-types';

import './styles.scss';

import Title from '../Title';

import { ReactComponent as IconCheck } from '../../assets/icons/icon-check-white.svg';

const Card = ({
  fieldTitle,
  fieldContent,
  isBoolean,
  boolQuestion,
  hasBorder,
  isTextarea,
  isCheckbox,
}) => {
  return (
    <div className={`card ${hasBorder ? 'has-border' : ''} ${isTextarea ? 'is-textarea' : ''}`}>
      {
        ! isBoolean ? (
          <div className="field">
            <h4 className="field-title">{ fieldTitle }</h4>
            <p className="field-content">{ fieldContent }</p>
          </div>
        ) : (
          <div className="wapper">
            <div className="bool">
              {
                isCheckbox && (
                  <div className="checkbox is-checked">
                    <IconCheck />
                  </div>
                )
              }

              <h3 className="bool-question">
                { boolQuestion }
              </h3>

            {
              fieldContent && ! isCheckbox ? (
                <Title
                  size="md"
                  value="SIM"
                  color="blue"
                  weight="bold"
                />
              ) : (
                <Title
                  size="md"
                  value="NÃO"
                  color="blue"
                  weight="bold"
                />
              )
            }
            </div>

            {
              fieldContent && (
                <div className="field">
                  <h4 className="field-title">{ fieldTitle }</h4>
                  <p className="field-content">{ fieldContent }</p>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

Card.propTypes = {
  fieldTitle: string,
  fieldContent: string,
  isBoolean: bool,
  boolQuestion: string,
  hasBorder: bool,
  isCheckbox: bool
};

export default Card;
