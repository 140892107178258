import { useState, useEffect } from 'react';

const prefixFactory = (prefix) => (entries) => entries.reduce((obj, entry) => {
  const object = obj;
  object[entry] = prefix + entry;

  return obj;
}, {});

export default prefixFactory;

export const useObjectState = (initial) => {
  const [state, setState] = useState(initial);

  return [
    state,

    (key, payload) => setState((state) => ({
      ...state,
      [key]: payload,
    })),

    payload => setState((state) => ({
      ...state,
      ...payload,
    })),
  ];
};

export const useDebounceValue = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [
      value, delay,
    ],
  );

  return debouncedValue;
};
