import './styles.scss';

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as IconBack } from '../../../assets/icons/icon-back.svg';
import { ReactComponent as IconDownload } from '../../../assets/icons/icon-download.svg';
import Card from '../../../components/Card';
import Title from '../../../components/Title';
import api from '../../../services/Auth/api';

const API_URL = (process.env.REACT_APP_API).replace('/api', '');

const base = {
  documents: 'Documentos (CPF e RG)',
  presentation: 'Apresentação do projeto',
  card: 'Cartão CNPJ da empresa proponente',
  history: 'Histórico de projetos realizados',
  publishments: 'Publicação de aprovação ou prorrogação dos projetos no diários oficial',
  information: 'Estatuto do Contrato Social com as respectivas atas atualizadas da empresa proponente',
};

const types = {
  cnpj: {
    ...base,
    documents: 'Documentos do representante legal (CPF e RG)',
  },

  cpf: base,
};

const Show = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [
    {
      law,
      name,
      value,
      files,
      overview,
      document,
      employees,
      submission_date,
      responsible_name,
      responsible_email,
      responsible_phone,
      conflict_interest,
      cpf_responsible_form,
      name_responsible_form,
      relationship_collaborator,
    },
    setSubmission,
  ] = useState({
    //
  });

  useEffect(() => {
    const fetchSubmission = async () => {
      setLoading(true);

      try {
        const {
          data,
        } = await api.get(`/project/${id}`);

        setSubmission(data.success);
      } catch (e) {
        // redirect to 404 page
        // or index
        // or whatever
      } finally {
        setLoading(false);
      }
    };

    fetchSubmission();
  }, [
    id,
  ]);

  const doc = document || 'cpf';

  const date = new Date(submission_date);

  return (
    <section className="submissions-show">
      {! loading && (
        <div className="container">
          <div className="submissions-show__header">
            <Link
              to="/submissoes"
            >
              <IconBack />
            </Link>
            <Title
              isTitle
              size="xlg"
              color="blue"
              weight="semi-bold"
              value={name}
            />
          </div>
          <div className="submissions-show__card">
            <div className="submissions-show__card-left">
              <Title
                isTitle
                size="lg"
                color="blue-dark"
                weight="semi-bold"
                value="Informações"
              />

              <Card
                fieldTitle="Nome do projeto:"
                fieldContent={name}
              />

              <Card
                fieldTitle="Valor do Projeto:"
                fieldContent={(value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              />

              <Card
                fieldTitle="Lei de Incentivo:"
                fieldContent={law && law.name}
              />

              <Card
                isTextarea
                fieldTitle="Área de atuação do projeto ou Instituição beneficiada:"
                fieldContent={overview}
              />

              <Card
                fieldTitle="Pessoa:"
                fieldContent={doc === 'cpf' ? 'Física' : 'Jurídica'}
              />

              {doc === 'cpf' && (
                <>
                  <Card
                    fieldTitle="Nome:"
                    fieldContent={responsible_name}
                  />
                  <Card
                    fieldTitle="E-mail:"
                    fieldContent={responsible_email}
                  />
                  <Card
                    fieldTitle="Telefone:"
                    fieldContent={responsible_phone}
                  />
                </>
              )}

              <Title
                isTitle
                size="lg"
                color="blue-dark"
                weight="semi-bold"
                value="Compliance"
                style={{ marginTop: '100px' }}
              />

              <Card
                isBoolean
                hasBorder
                fieldContent={employees}
                fieldTitle="Qual a relação e quem é essa pessoa?"
                boolQuestion="O proponente ou algum dos terceiros listados em seu projeto faz
                  parte do quadro de funcionários ou acionistas do Grupo Algar?"
              />

              <Card
                isBoolean
                hasBorder
                fieldContent={relationship_collaborator}
                fieldTitle="Qual é a relação e quem é essa pessoa?"
                boolQuestion="O proponente ou algum dos terceiros listados em seu projeto é
                  funcionário público, seja de esfera municipal, estadual ou federal?"
              />

              <Card
                isBoolean
                hasBorder
                fieldContent={conflict_interest}
                fieldTitle="Qual a relação e quem é esta pessoa?"
                boolQuestion="
                  Considerando todas as pessoas envolvidas no seu projeto, você afirma
                  que não há relações que gerem conflito de interesse? Por conflito de
                  interesse entende-se: quando questões diversas (profissionais, financeiras,
                  familiares, políticas ou pessoais) podem interferir no julgamento das
                  pessoas ao exercerem suas ações dentro das organizações
                  e projetos sociais, favorecendo ou facilitando qualquer etapa do processo
                  em benefício próprio ou de alguém.
                "
              />

              <Card
                hasBorder
                fieldContent={name_responsible_form}
                fieldTitle="Nome do responsável pelo preenchimento deste formulário"
              />

              <Card
                hasBorder
                fieldContent={cpf_responsible_form}
                fieldTitle="CPF do responsável pelo preenchimento deste formulário"
              />

              <Card
                hasBorder
                isBoolean
                isCheckbox
                boolQuestion="Declaro, para os devidos fins, que as informações fornecidas no
                  Formulário de Conflito de Interesses são verdadeiras, condizentes
                  com os fatos à época de seu preenchimento e não ocultaram quaisquer
                  dados. Se em algum momento as informações prestadas deixarem
                  de representar a realidade, concordo em comunicar as mudanças
                  imediatamente ao grupo Algar através do e-mail incentivo@algar.com.br"
              />

              <Title
                size="xs"
                color="dark"
                style={{ marginTop: '40px' }}
                value={`Formulário enviado em: ${date.toLocaleDateString('pt-BR')}`}
              />
            </div>

            <div className="submissions-show__card-right">
              {Object.keys(types[doc]).map((type) => {
                const file = files.find((archive) => archive.type === type);

                if (! file) {
                  return null;
                }

                return (
                  <div className="submissions-show__card-right-content">
                    <Title
                      isTitle
                      size="md"
                      color="blue-dark"
                      weight="semi-bold"
                      value={types[doc][type]}
                    />

                    <div className="submissions-show__card-right-content-document">
                      <span className="submissions-show__card-right-content-document-title">{file.original_name}</span>
                      <a
                        rel="noopener noreferrer"
                        href={`${API_URL}${file.url}`}
                        target="_blank"
                        className="submissions-show__card-right-content-document-button"
                      >
                        <IconDownload />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Show;
