import './styles.scss';

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import { format, addDays, parseISO } from 'date-fns';

import { ReactComponent as IconCalendar } from '../../assets/icons/icon-calendar.svg';
import { ReactComponent as IconChevron } from '../../assets/icons/icon-chevron.svg';
import IconSearch from '../../assets/icons/icon-search.svg';
import DatePicker from '../../components/Form/DatePicker';
import Input from '../../components/Form/Input';
import Pagination from '../../components/Pagination';
import Title from '../../components/Title';
import { useObjectState, useDebounceValue } from '../../helpers';
import api from '../../services/Auth/api';

const titles = ['Nome do Projeto', 'Lei de Incentivo', 'Data do envio'];

const Submissions = () => {
  const form = useRef(null);

  const [
    state,
    set,
    merge,
  ] = useObjectState({
    page: 1,
    pages: 1,
    name: '',
    end: null,
    law_id: '',
    start: null,
    categories: [],
    current_page: 1,
    submissions: [],
  });

  const {
    end,
    name,
    page,
    start,
    pages,
    law_id,
    categories,
    submissions,
    current_page,
  } = state;

  const debounced = useDebounceValue(name, 500);

  useEffect(() => {
    const fetchLaws = async () => {
      const {
        data,
      } = await api.get('/law');

      merge({
        categories: [
          {
            value: '',
            label: 'Todas',
          },

          ...data.success.map(({ id: value, name: label }) => ({
            label,
            value,
          })),
        ],
      });
    };

    fetchLaws();
  }, [
    //
  ]);

  useEffect(() => {
    const fetchProjects = async () => {
      const add = end ? addDays(end, 1) : null;

      const response = await api.get('/project', {
        params: {
          page,
          law_id,
          date_max: add,
          date_min: start,
          name: debounced,
        },
      });

      const {
        data,
        last_page,
        current_page,
      } = response.data.success;

      merge({
        current_page,
        submissions: data,
        pages: last_page,
      });
    };

    fetchProjects();
  }, [end, start, page, law_id, debounced]);

  const lawOptions = [
    ...categories.map(({ label, value }, index) => (
      <option
        key={`${label}${index}`}
        value={value}
      >
        {label}
      </option>
    )),
  ];

  return (
    <section className="submissions">
      <div className="container">
        <div className="submissions__header">
          <Title
            isTitle
            size="xlg"
            color="blue"
            weight="semi-bold"
            value="Submissões"
          />
          <Form onSubmit={() => { }}>
            <Input
              input
              appearence="secondary"
              type="text"
              size="sm"
              placeholder="Buscar pelo nome"
              name="name"
              hasIcon
              icon={IconSearch}
              onInput={(value) => set('name', value)}
            />
          </Form>
        </div>
        <div className="submissions__card">
          <div className="submissions__card-top">
            <Form className="submissions__card-top-date">
              <div className="date-picker">
                <IconCalendar />
                <span className="date-picker__span">De</span>
                <DatePicker
                  selected={start}
                  onSelect={(date) => set('start', date)}
                  selectsStart
                  startDate={start}
                  endDate={end}
                  maxDate={end}
                  name="startDate"
                  dateFormat="dd/MM/Y"
                  placeholderText="selecione uma data"
                />
              </div>

              <div className="date-picker">
                <IconCalendar />
                <span className="date-picker__span">Até</span>
                <DatePicker
                  selected={end}
                  onSelect={(date) => set('end', date)}
                  selectsEnd
                  startDate={start}
                  endDate={end}
                  minDate={start}
                  name="endDate"
                  dateFormat="dd/MM/Y"
                  placeholderText="selecione uma data"
                />
              </div>
            </Form>
            <Form
              ref={form}
            >
              <Input
                select
                required
                defaultValue={law_id}
                name="law"
                onInput={(value) => set('law_id', value)}
              >
                {lawOptions}
              </Input>
            </Form>
          </div>
          <div className="submissions__card-bottom">
            <table className="submissions__card-bottom-table">
              <thead>
                <tr>
                  {titles && titles.map((item) => (
                    <th key={item}>
                      <Title
                        size="xxs"
                        color="blue-dark"
                        weight="semi-bold"
                        value={item}
                      />
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {submissions.map(({
                  name, id, law, submission_date,
                }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/submissoes/${id}`}>
                        <p className="submissions__card-bottom-table-content">{name}</p>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/submissoes/${id}`}>
                        <p className="submissions__card-bottom-table-content">{law && law.name}</p>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/submissoes/${id}`}>
                        <p className="submissions__card-bottom-table-content">{submission_date ? format(parseISO(submission_date), 'dd/MM/yyyy') : '-'}</p>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/submissoes/${id}`}>
                        <IconChevron />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          pages={pages}
          currentPage={current_page}
          onPaginate={(paged) => set('page', paged)}
        />
      </div>
    </section>
  );
};

export default Submissions;
