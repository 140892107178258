import './styles.scss';

import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';

import { useField } from '@unform/core';
import ptBr from 'date-fns/locale/pt-BR';
import { string, shape, bool } from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({
  name,
  label,
  ...rest
}) => {
  const datepickerRef = useRef(null);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);
  const [date, setDate] = useState(defaultValue || null);

  registerLocale('pt-BR', ptBr);
  setDefaultLocale('pt-BR', ptBr);

  useEffect(() => {
    registerLocale({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);


  return (
    <div className="input input--secondary calendar">
      <div className="input__head">
        {label && (
          <label
            htmlFor={fieldName}
            className="input__label"
          >
            {label}
          </label>
        )}
        {error && (<span className="input__error">{error}</span>)}
      </div>
      <ReactDatePicker
        {...rest}
        ref={datepickerRef}
        showPopperArrow
        selected={date}
        onChange={setDate}
        className="input__target calendar__target"
        calendarClassName="calendar__poppover"
      />
    </div>
  );
};

DatePicker.propTypes = {
  name: string.isRequired,
  label: shape(string, bool),
};

DatePicker.defaultProps = {
  label: null,
};

export default DatePicker;
