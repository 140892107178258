import './styles.scss';
import React from 'react';

import classNames from 'classnames';
import {
  element, bool, func, arrayOf,
} from 'prop-types';

import IconClose from '../../assets/icons/icon-close-modal.svg';

const Modal = ({
  checked,
  onChecked,
  children,
  onSend,
}) => {
  const styled = classNames(
    'modal',
    checked && 'modal--active',
  );

  return (
    <div
      className={styled}
    >
      <div className="modal__wrapper">
        <button
          className="modal__wrapper-button"
          type="button"
          onClick={() => {
            onChecked(! checked);
            onSend(false);
          }}
        >
          <img
            src={IconClose}
            alt=""
          />
        </button>
        <div className="modal__wrapper-card">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  checked: bool,
  onChecked: func,
  children: arrayOf(element),
  onSend: func,
};

Modal.defaultProps = {
  checked: false,
  onChecked: () => {},
  children: null,
  onSend: () => {},
};

export default Modal;
