import React from 'react';

import { number, func } from 'prop-types';

import { ReactComponent as IconPaginator } from '../../assets/icons/icon-paginator.svg';

const factory = ({
  pages,
  limit,
  margin,
  currentPage,
}) => {
  if (pages <= limit + 2) {
    return pages;
  }

  const elements = {};

  const halfRange = Math.floor(limit / 2);

  for (let i = 0; i < margin; i++) {
    elements[i] = i + 1;
  }

  let lowRange = currentPage - halfRange > 0
    ? currentPage - 1 - halfRange
    : 0;

  let highRange = lowRange + limit - 1;

  if (highRange >= pages) {
    highRange = pages - 1;
    lowRange = highRange - limit + 1;
  }

  for (let index = lowRange; index <= highRange && index <= pages - 1; index++) {
    elements[index] = index + 1;
  }

  if (lowRange > margin) {
    elements[lowRange - 1] = '...';
  }

  if (highRange + 1 < pages - margin) {
    elements[highRange + 1] = '...';
  }

  for (let index = pages - 1; index >= pages - margin; index--) {
    elements[index] = index + 1;
  }

  return elements;
};

const Pagination = ({
  pages,
  limit,
  margin,
  onPaginate,
  currentPage,
}) => {
  if (pages <= 1) {
    return null;
  }

  const pagination = factory({
    pages,
    limit,
    margin,
    currentPage,
  });

  const go = (page) => {
    if (page === currentPage || page === '...') {
      return;
    }

    onPaginate(page);
  };

  const elements = [];

  for (const index in pagination) {
    const page = pagination[index];

    elements.push(
      <button
        type="button"
        key={`${index}${page}`}
        onClick={() => go(page)}
        className={`submissions__paginator-pages-page ${page === currentPage ? 'submissions__paginator-pages-page--active' : ''}`}
      >
        {page}
      </button>,
    );
  }

  const previousPage = () => {
    if (currentPage === 1) {
      return;
    }

    onPaginate(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage === pages) {
      return;
    }

    onPaginate(currentPage + 1);
  };

  return (
    <nav className="submissions__paginator">
      <button
        className="submissions__paginator-button submissions__paginator-button--left"
        onClick={previousPage}
        disabled={currentPage === 1}
        type="button"
      >
        <IconPaginator />
      </button>

      <div className="submissions__paginator-pages">
        {elements}
      </div>

      <button
        type="button"
        onClick={nextPage}
        disabled={currentPage === pages}
        className="submissions__paginator-button submissions__paginator-button--right"
      >
        <IconPaginator />
      </button>
    </nav>
  );
};

Pagination.propTypes = {
  limit: number,
  margin: number,
  onPaginate: func,
  pages: number.isRequired,
  currentPage: number.isRequired,
};

Pagination.defaultProps = {
  limit: 3,
  margin: 1,
  onPaginate: () => {},
};

export default Pagination;
