import './styles.scss';

import React, { useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import classNames from 'classnames';
import {
  string,
  bool,
  arrayOf,
  element,
  func,
} from 'prop-types';

import { ReactComponent as IconArrow } from '../../../assets/icons/icon-arrow.svg';
import { ReactComponent as IconLaws } from '../../../assets/icons/icon-laws.svg';
import prefixFactory from '../../../helpers';


const prefix = prefixFactory('input--');

const appearences = prefix([
  'primary',
  'secondary',
]);

const sizes = prefix([
  'sm',
  'md',
  'default',
]);

const Input = ({
  name,
  label,
  textarea,
  input,
  select,
  children,
  appearence,
  size,
  hasLabel,
  hasIcon,
  icon,
  onInput,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  const handleInput = ({ target }) => {
    onInput(target.value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const classes = classNames(
    'input',
    error && 'input--error',
    appearences[appearence],
    sizes[size],
    hasIcon && 'input--icon',
    select && 'input--select',
  );

  return (
    <div className={classes}>
      {hasLabel && (
        <label
          className="input__label"
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <div className="input__target">
        {textarea && (
          <textarea
            {...rest}
            onInput={handleInput}
            onChange={handleInput}
            className="input__target-content"
            id={fieldName}
            ref={inputRef}
            defaultValue={defaultValue}
            rows={5}
          />
        )}
        {input && (
          <>
            {hasIcon && (
            <img
              src={icon}
              alt=""
              className="input__target-icon"
            />
            )}
            <input
              {...rest}
              className="input__target-content"
              id={fieldName}
              onChange={handleInput}
              ref={inputRef}
              defaultValue={defaultValue}
            />
          </>
        )}
        {select && (
          <>
            <IconLaws />
            <select
              {...rest}
              className="input__target-content"
              id={fieldName}
              ref={inputRef}
              onChange={handleInput}
              defaultValue={defaultValue}
            >
              {children}
            </select>
            <IconArrow />
          </>
        )}
      </div>
      {error && (<span className="input__error">{error}</span>)}
    </div>
  );
};

Input.propTypes = {
  appearence: string,
  name: string.isRequired,
  label: string,
  textarea: bool,
  input: bool,
  select: bool,
  children: arrayOf(element),
  size: string,
  hasLabel: bool,
  hasIcon: bool,
  icon: string,
  onInput: func,
};

Input.defaultProps = {
  appearence: 'primary',
  textarea: false,
  input: false,
  select: false,
  children: [],
  size: 'default',
  hasLabel: false,
  label: '',
  onInput: () => {},
  hasIcon: false,
  icon: '',
};

export default Input;
