import './styles.scss';
import React from 'react';

import classNames from 'classnames';
import { string, bool, func } from 'prop-types';

const Radio = ({
  id,
  label,
  onCheck,
  isChecked,
  ...rest
}) => {
  const styled = classNames(
    'radio',
    isChecked && 'radio--selected',
  );

  return (
    <div className={styled}>
      <input
        {...rest}
        id={id}
        type="radio"
        name={id}
        value={label}
        checked={isChecked}
        onChange={onCheck}
        className="radio__target"
      />
      <label
        htmlFor={id}
        className="radio__label"
      >
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  onCheck: func,
  isChecked: bool,
};

Radio.defaultProps = {
  isChecked: false,
  onCheck: () => {},
};

export default Radio;
