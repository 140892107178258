import './styles.scss';
import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import prefixFactory from '../../helpers';

const prefix = prefixFactory('title--');

const sizes = prefix([
  'xxs',
  'xs',
  'lg',
  'xlg',
  'md',
  'xl',
  'xxl',
]);

const colors = prefix([
  'blue',
  'blue-dark',
  'dark',
  'white',
]);

const weights = prefix([
  'medium',
  'semi-bold',
  'bold',
]);

const Title = ({
  isTitle,
  value,
  size,
  color,
  weight,
  className,
  ...rest
}) => {
  const styled = classNames(
    'title',
    className,
    sizes[size],
    colors[color],
    weights[weight],
  );

  return (
    <>
    {isTitle ? (
      <h1 className={styled} {...rest}>{value}</h1>
    ) : (
      <h2 className={styled} {...rest}>{value}</h2>
    )}
    </>
  );
};

Title.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  className: PropTypes.string,
  isTitle: PropTypes.bool,
};

Title.defaultProps = {
  size: 'md',
  color: 'blue-dark',
  weight: 'semi-bold',
  className: '',
  isTitle: false,
};

export default Title;
