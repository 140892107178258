import './styles.scss';

import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import Triangles from '../../assets/images/background-triangles.svg';
import Logo from '../../assets/images/icon-logo.png';
import ImageBackground from '../../assets/images/image-background-login.jpg';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import Title from '../../components/Title';
import api from '../../services/Auth/api';
import { login } from '../../services/Auth/auth';

const Login = () => {
  const formRef = useRef(null);
  const history = useHistory();

  async function handleSubmit (data, { reset }) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('Email incorreto!'),
        password: Yup.string().required('Não esqueça de digitar a senha!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/login', data);

      login(response.data.success.token);

      history.push('/submissoes');

      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      if (err && err.response && Number(err.response.status) === 401) {
        formRef.current.setErrors({
          password: 'Senha incorreta',
        });
      }
    }
  }

  return (
    <section className="login">
      <div className="container">
        <img
          src={Triangles}
          alt=""
          className="login__image--left"
        />
        <img
          src={Triangles}
          alt=""
          className="login__image--right"
        />
        <div
          className="login__left"
          style={{ backgroundImage: `url(${ImageBackground})` }}
        >
          <Title
            isTitle
            value="Painel de Projetos"
            size="xxl"
            color="white"
            weight="medium"
            className="login__left-title"
          />
          <img
            src={Logo}
            alt=""
            className="login__left-logo"
          />
        </div>

        <div className="login__right">
          <Title
            isTitle
            value="Login"
            size="xl"
            color="blue"
            medium
            className="login__right-title"
          />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Input
              input
              label="E-mail"
              appearence="primary"
              type="email"
              size="default"
              hasLabel
              placeholder="Insira seu e-mail"
              name="email"
            />
            <Input
              input
              label="Senha"
              appearence="primary"
              type="password"
              size="default"
              hasLabel
              placeholder="Insira sua senha"
              name="password"
            />

            <Button
              value="Acessar Conta"
              submit
            />
          </Form>
        </div>
      </div>
    </section>
  );
};

export default Login;
