import './styles.scss';

import React, { useState, useRef, useEffect } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import IconCheck from '../../assets/icons/icon-check.svg';
import { ReactComponent as IconPen } from '../../assets/icons/icon-pen.svg';
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import api from '../../services/Auth/api';


const Laws = () => {
  const formRef = useRef(null);
  const formRefLaw = useRef(null);

  const [modal, setModal] = useState(false);
  const [modalEditLaw, setModalEditLaw] = useState(false);
  const [modalDeleteLaw, setModalDeleteLaw] = useState(false);
  const [modalConfirmDeleteLaw, setModalConfirmDeleteLaw] = useState(false);
  const [send, setSend] = useState(false);
  const [editLaw, setEditLaw] = useState(false);
  const [laws, setLaws] = useState([]);
  const [activeEdit, setActiveEdit] = useState('');
  const [activeDelete, setActiveDelete] = useState('');

  async function getLaws () {
    const response = await api.get('/law');
    setLaws(response.data.success);
  }

  async function handleDelete (id) {
    await api.delete(`/law/${id}`);
    await getLaws();
  }

  async function handleSubmit (data, { reset }) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Digite o nome da Lei!'),
        confirmation_answer: Yup.string().required('Digite a mensagem de confirmação!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/law', data);
      await getLaws();

      setSend(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRefLaw.current.setErrors(errorMessages);
      }
    }

    reset();
  }

  async function handleSubmitLaw (data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Digite o nome!'),
        confirmation_answer: Yup.string().required('Digite a mensagem de confirmação!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const id = formRef.current.getFieldValue('id');

      await api.put(`/law/${id}`, data);
      await getLaws();

      setEditLaw(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  useEffect(() => {
    getLaws();
  }, []);

  return (
    <section className="laws">
      <div className="container">
        <div className="laws__header">
          <Title
            isTitle
            size="xlg"
            color="blue"
            weight="semi-bold"
            value="Leis Cadastradas"
          />
          <Button
            type="primary"
            size="md"
            value="Cadastrar Lei"
            iconPlus
            onClick={() => setModal(! modal)}
          />
        </div>
        <div className="laws__card">
          <div className="laws__card-head">
            <Title
              size="xxs"
              color="blue-dark"
              weight="semi-bold"
              value="Nome da Lei"
            />
          </div>
          <div className="laws__card-body">
            {laws.map((item, index) => (
              <>
                <div
                  className="laws__card-body-item"
                  key={item.name}
                >
                  <p className="laws__card-body-item-title">{item.name}</p>
                  <div className="laws__card-body-item-buttons">
                    <div
                      role="presentation"
                      className="laws__card-body-item-buttons-button"
                      onClick={() => {
                        setModalEditLaw(! modalEditLaw);
                        setActiveEdit(index);
                      }}
                    >
                      <IconPen />
                      <span className="laws__card-body-item-buttons-button-content laws__card-body-item-buttons-button-content--blue">Editar</span>
                    </div>
                    <div
                      role="presentation"
                      className="laws__card-body-item-buttons-button"
                      onClick={() => {
                        setModalDeleteLaw(! modalDeleteLaw);
                        setActiveDelete(index);
                      }}
                    >
                      <IconTrash />
                      <span className="laws__card-body-item-buttons-button-content laws__card-body-item-buttons-button-content--red">Exluir</span>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        {laws.map((item, index) => (
          <>
            {activeDelete === index && (
              <>
                <Modal
                  checked={modalConfirmDeleteLaw}
                  onChecked={setModalConfirmDeleteLaw}
                >
                  <div className="modal__wrapper-card-success">
                    <img
                      src={IconCheck}
                      alt=""
                    />
                    <h1 className="modal__wrapper-card-success-title">Lei excluída com sucesso</h1>
                  </div>
                </Modal>
                <Modal
                  checked={modalDeleteLaw}
                  onChecked={setModalDeleteLaw}
                >
                  <div className="modal__wrapper-card-delete">
                    <Title
                      isTitle
                      size="xlg"
                      color="blue-dark"
                      weight="medium"
                      value="Tem certeza que deseja excluir essa lei?"
                    />
                    <div className="modal__wrapper-card-delete-buttons">
                      <Button
                        value="NÃO"
                        type="secondary"
                        size="xlg"
                        onClick={() => setModalDeleteLaw(! modalDeleteLaw)}
                      />
                      <Button
                        value="SIM"
                        type="tertiary"
                        size="xlg"
                        onClick={() => {
                          setModalDeleteLaw(! modalDeleteLaw);
                          setModalConfirmDeleteLaw(! modalConfirmDeleteLaw);
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </>
        ))}
        {laws.map((item, index) => (
          <>
            {activeEdit === index && (
              <>
                <Modal
                  checked={modalEditLaw}
                  onChecked={setModalEditLaw}
                  onSend={setEditLaw}
                >
                  {! editLaw ? (
                    <div>
                      <Title
                        isTitle
                        size="xlg"
                        color="blue"
                        weight="medium"
                        value="Editar Lei"
                      />
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmitLaw}
                        initialData={{ name: item.name, confirmation_answer: item.confirmation_answer }}
                      >
                        <Input
                          input
                          hidden
                          value={item.id}
                          name="id"
                        />
                        <Input
                          input
                          hasLabel
                          label="Nome da Lei"
                          appearence="secondary"
                          type="text"
                          size="md"
                          placeholder="Insira o nome da lei"
                          name="name"
                        />
                        <Input
                          hasLabel
                          label="Mensagem de Confirmação"
                          appearence="secondary"
                          size="md"
                          placeholder="Insira a mensagem de confirmação"
                          name="confirmation_answer"
                          textarea
                        />
                        <Button
                          value="Confirmar"
                          submit
                        />
                      </Form>
                    </div>
                  ) : (
                    <>
                      <div className="modal__wrapper-card-success">
                        <img
                          src={IconCheck}
                          alt=""
                        />
                        <h1 className="modal__wrapper-card-success-title">Lei editada com sucesso</h1>
                      </div>
                    </>
                  )}
                </Modal>
              </>
            )}
          </>
        ))}
        <Modal
          checked={modal}
          onChecked={setModal}
          onSend={setSend}
        >
          {! send ? (
            <div>
              <Title
                isTitle
                size="xlg"
                color="blue"
                weight="medium"
                value="Cadastro de Lei"
              />
              <Form
                onSubmit={handleSubmit}
                ref={formRefLaw}
              >
                <Input
                  input
                  hasLabel
                  label="Nome da Lei"
                  appearence="secondary"
                  type="text"
                  size="md"
                  placeholder="Insira o nome da lei"
                  name="name"
                />
                <Input
                  hasLabel
                  label="Mensagem de Confirmação"
                  appearence="secondary"
                  type="text"
                  size="md"
                  placeholder="Insira a mensagem de confirmação"
                  name="confirmation_answer"
                  textarea
                />
                <Button
                  value="Cadastrar"
                  submit
                />
              </Form>
            </div>
          ) : (
            <>
              <div className="modal__wrapper-card-success">
                <img
                  src={IconCheck}
                  alt=""
                />
                <h1 className="modal__wrapper-card-success-title">Nova Lei Cadastrada</h1>
              </div>
            </>
          )}
        </Modal>

      </div>
    </section>
  );
};

export default Laws;
