import './styles.scss';

import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Logout from '../../assets/icons/icon-logout.svg';
import Logo from '../../assets/images/header-logo.png';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import api from '../../services/Auth/api';
import { logout } from '../../services/Auth/auth';

const links = [
  {
    route: '/submissoes',
    title: 'Submissões',
  },
  {
    route: '/usuarios',
    title: 'Usuários',
  },
  {
    route: '/leis',
    title: 'Leis',
  },
];
const Head = () => {
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(false);


  useEffect(() => {
    async function getUser () {
      const response = await api.get('/me');
      setUser(response.data.success);
    }

    getUser();
  }, []);

  return (
    <section className="head">
      <div className="container">
        <Link
          className="head__logo"
          to="/submissoes"
        >
          <img
            src={Logo}
            alt=""
          />
        </Link>
        <div className="head__links">

          {links && links.map((item) => (
            <NavLink
              className="head__links-link"
              to={item.route}
              activeClassName="head__links-link--active"
              key={item.title}
            >
              <h3 className="head__links-link-item">{item.title}</h3>
            </NavLink>
          ))}
        </div>
        <div className="head__user">
          <h3 className="head__user-name">{user.name}</h3>
          <button
            className="head__user-logout"
            onClick={() => setModal(! modal)}
            type="button"
          >
            <img
              src={Logout}
              alt=""
            />
          </button>
        </div>
        <Modal
          checked={modal}
          onChecked={setModal}
        >
          <Title
            isTitle
            size="xlg"
            color="blue-dark"
            weight="medium"
            value="Tem certeza que deseja sair?"
          />
          <div className="modal__wrapper-card-buttons">
            <Button
              value="NÃO"
              type="secondary"
              size="xlg"
              onClick={() => setModal(! modal)}
            />
            <Link
              to="/"
              onClick={() => logout(true)}
            >
              <Button
                value="SIM"
                type="tertiary"
                size="xlg"
              />
            </Link>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default Head;
